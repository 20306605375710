.slick-dots {
  position: absolute;
  top: -5%;
  left: 13%;
  transform: rotate(90deg);
}

.slick-dots li button:before {
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  color: #8c95ea;
}

.rounded img {
  border-radius: 50%;
}

.slick-dots li {
  margin: 0px;
}

.slick-dots li button:before {
  font-size: 8px;
  line-height: 1px;
  opacity: 1;
}

.fix-mui-padding .MuiSelect-outlined {
  padding: 13.5px 14px;
}
.lecture-slide .slick-prev,
.lecture-slide .slick-next {
  background: black;
  padding: 12px 2px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 50;
}
.lecture-slide .slick-prev::before {
  font-size: 40px;
}
.lecture-slide .slick-next::before {
  font-size: 40px;
}
.lecture-slide .slick-disabled {
  display: none !important;
}
